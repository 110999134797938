<template>
    <div :class="[light?'lightBack':'darkBack', 'bookContainer']">
        <div :class="[light?'lightBackInfo':'darkBackinfo', 'bookChapter']">
            <header :class="[light ? 'lightBottonBorder' : 'darkBottonBorder', 'bookHeader']">
                <a href="#" :class="{ lightA: light, darkA: dark }">
                    <img src="./demoImg/home.png" alt="">
                </a>
                <div class="bookTitle">
                    <h1>[(新刊)丹溪心法</h1>
                    <h2>章节</h2>
                </div>
            </header>
            <div class="chapterMain">
                <div class="contentWrap" v-html="chapterContent"></div>
                <div class="controlWrap">
                    <div class="buttons">
                        <button :class="[light ? 'lightButton' : 'darkButton' ]">上一页</button>
                        <button :class="[light ? 'lightButton' : 'darkButton' ]">下一页</button>
                    </div>
                </div>
            </div>
        </div>
        <div :class="[light?'lightBackInfo':'darkBackinfo', 'barWrap']">
            <div class="readBar">
                <div class="barItem" @click="showList = true">
                    <img src="./demoImg/barItem1.png" alt="">
                </div>
                <div class="barItem" :class="[light?'lightMiddleBarItem':'darkMiddleBarItem', 'barItem']">
                    <img src="./demoImg/barItem2.png" alt="" @click="showSizeControl = !showSizeControl">
                    <div v-if="showSizeControl"
                         :class="[light?'lightBorder':'darkBorder', 'sizeControlWrap']">
                        <div class="sizeControlInfo">
                            <div class="sciItem1">
                                <img src="./demoImg/sizeControl1.png" alt="">
                            </div>
                            <div class="sciItem2">
                                <el-slider
                                    v-model="fontSize"
                                    :step="1"
                                    :max="7"
                                    :show-tooltip="false"
                                    @input="changeFontSize($event)"></el-slider>
                            </div>
                            <div class="sciItem3">
                                <img src="./demoImg/sizeControl2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="barItem" @click="changeTheme">
                    <img src="./demoImg/barItem3.png" alt="">
                </div>
            </div>
        </div>
        <div v-show="showList" class="bookList">
            <div class="overlay" @click="showList = false"></div>
            <div :class="[light?'lightBackInfo':'darkBackinfo', 'popupInfo']">
                <div class="pwiTitle">目录</div>
                <div class="pwiList">
                    <ul>
                        <li
                            v-for="(item, index) in chapterList"
                            :key="index"
                            :class="{ chapterChildLi: item.type===2, lightLi: light, darkLi: dark }">
                            {{ item.chapterName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div :class="[light?'lightBackInfo':'darkBackinfo', 'trialProgress']">
            <div class="tpText">本书还剩99%</div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            showList: false,
            showSizeControl: false,
            fontSize: 0,
            customColor: '#409eff',
            chapterContent: "<p>左金丸\n" +
                "  治肝火。一名回令丸。\n" +
                "  黄连（六两，一本作芩）  吴茱萸（一两或半两） \n" +
                "  上为末，水丸或蒸饼丸。白汤下五十丸。 \n" +
                "〔附录〕\n" +
                "  诸热瞀瘈，暴瘖冒昧，躁扰狂越，骂詈惊骇，胕肿疼酸，气逆冲上，禁慄如丧神守，啑呕，疮疡，喉痹，耳鸣及聋，呕涌溢食不下，目昧不明，暴注，瞤，瘈，暴病，暴死，五志七情过极，皆属火也。\n" +
                "  火者有二，君火，人火也；曰相火，天火也。火内阴而外阳，主乎动者也。故凡动，皆属火。以名而言，形质相生，配于五行，故谓之君；以位而言，生于虚无，守位禀命，因动而见，故谓之相。肾肝之阴，悉其相火。东垣曰：相火，元气之贼火，与元气不相两立，一胜则一负。然则如之何则可使之无胜负乎？周子曰：神发知矣。五性感动而万事出，有知之后，五者之性，为物所感，不能不动。谓之动者，即《内经》五火也。相火易起，五性厥阳之火相扇，则妄动矣。火起于妄，变化莫测，无时不有，煎熬真阴，阴虚则病，阴绝则死。君火之气，经以暑与热言之。相火之气，经以火言之，盖表其暴悍酷烈，有甚于君火者也。故曰：相火，元气之贼。周子又曰：圣人定之以中正仁义而主静。朱子亦曰：必使道心常为一身之主，而人心每听命焉，此善处乎火者。人心听命于道心，而又能主之以静，彼五火将寂然不作。而相火者惟有裨补造化，而为生生不息之运用尔，何贼之有？\n" +
                "〔附方〕\n" +
                "  东垣泻阴火升阳汤\n" +
                "  治肌热烦热，面赤食少，喘咳痰盛。\n" +
                "  羌活  甘草（炙）  黄芪  苍术（各一两）  升麻（八钱）  柴胡（两半）  人参  黄芩（各七钱）  黄连（酒炒，半两）  石膏（半两，秋深不用）\n" +
                "  上㕮咀，每服一两或半两，水煎。此药发脾胃火邪，又心胆肝肺膀胱药也，泻阴火，升发阳气，荣养气血者也。\n" +
                "  升阳散火汤\n" +
                "  治男子妇人四肢发热，肌热，筋痹热，骨髓中热，发困，热如燎，扪之烙手。此病多因血虚而得之，或胃虚过食冷物，抑遏阳气于脾土，火郁则发之。\n" +
                "  升麻  葛根  独活  羌活（各半两）  防风（二钱半）  柴胡（八钱）  甘草（炙，三钱）  人参  白芍（各半两）  甘草（生，二钱）\n" +
                "  上㕮咀，每服半两或一两，水煎，稍热服。\n" +
                "  地骨皮散\n" +
                "  治浑身壮热，脉长而滑，阳毒火炽发渴。\n" +
                "  地骨皮  茯苓（各半两）  柴胡  黄芩  生芐  知母（各一两）  石膏（二两）  羌活  麻黄（各七钱半，有汗并去之）\n" +
                "  上㕮咀，每服一两，入姜煎。\n" +
                "  黄连解毒汤（见暑类）\n" +
                "新刊丹溪心法卷之一终\n" +
                "新刊丹溪先生心法卷之二\n" +
                "新安后学黄峰蒋奎永光校正\n" +
                "古潭德夫黄玉刊\n" +
                "癍疹七\n" +
                "  癍属风热挟痰而作，自里而发于外，通圣散中消息，当以微汗散之，切不可下。内伤癍者，胃气极虚，一身火游行于外所致，宜补以降，于《阴证略例》中求之。发癍似伤寒者，痰热之病发于外，微汗以散之，若下之非理。疹属热与痰，在肺，清肺火降痰，或解散出汗，亦有可下者。疹即疮疹，汗之即愈，通圣散中消息之。瘾疹多属脾，隐隐然在皮肤之间，故言瘾疹也。发则多痒或不仁者，是兼风兼温之殊，色红者，兼火化也。黄瓜水调伏龙肝去红点癍。\n" +
                "  戴云∶癍有色点而无头粒者是也；疹浮小有头粒者，随出即收，收则又出是也，非若癍之无头粒者，当明辨之。\n" +
                "〔附录〕\n" +
                "  癍疹之病，其为证各异。疮发焮肿于外者，属少阳三焦相火也，谓之癍；小红靥行皮肤之中不出者，属少阴君火也，谓之疹。又伤寒阳证发癍有四，惟温毒发癍至重，红赤者为胃热也，紫黑者为胃烂也，一则下早，一则下之晚，乃外感热病发癍也，以玄参、升麻、白虎等药服之。阴证发癍，亦出背胸，又出手足亦稀少而微红，若作热证，投之凉药大误矣。此无根失守之火聚于胸中，上独熏肺，传于皮肤而为癍点，但如蚊、蚋、虱、蚤咬形状，而非锦纹也。只宜调中温胃，加以茴香、芍药，或以大建中之类，其火自下，癍自消退，可谓治本而不治标也。\n" +
                "〔附方〕\n" +
                "  调中汤\n" +
                "  治内伤外感而发阴癍。\n" +
                "  苍术（一钱半）  陈皮（一钱）  砂仁  藿香  芍药（炒）  甘草（炙）  桔梗  半夏  白芷  羌活  枳壳（各一钱）  川芎（五分）  麻黄  桂枝（各五分）\n" +
                "  上㕮咀，姜三片，水煎服。\n" +
                "  消毒犀角饮子\n" +
                "  治癍及瘾疹。\n" +
                "  牛蒡子（六钱）  荆芥  防风（各三钱）  甘草（一钱）\n" +
                "  上㕮咀，水煎。\n" +
                "  通圣散（出丹溪经验方）\n" +
                "  川芎  当归  麻黄  薄荷  连翘  白芍  黄芩  石膏  桔梗（一两）  滑石（三两）  荆芥  栀子  白术（二钱半）  甘草\n" +
                "  上剉，水煎服。如身疼，加苍术、羌活；痰嗽，加半夏。每服细末三钱，生姜三片，擂细荡起，煎沸服之。\n" +
                "  玄参升麻汤\n" +
                "  癍在身，治汗下吐后毒不散，表虚里实发于外，甚则烦燥谵妄。\n" +
                "  玄参  升麻  甘草（等分）\n" +
                "  上㕮咀，水煎。\n" +
                "  化癍汤\n" +
                "  治伤寒汗吐下后，癍发脉虚。\n" +
                "  白虎汤加人参，守真再加白术。\n" +
                "  上㕮咀，时时煎服。\n" +
                "  大建中汤\n" +
                "  黄芪  当归  桂心  芍药（各二钱）  人参  甘草（各一钱）  半夏  黑附（炮去皮，各二钱五分）\n" +
                "  上㕮咀，每服五钱，水二盏，姜三片，枣二枚煎，食前服。\n" +
                "疟八\n" +
                "  疟疾有风、暑、食、痰、老疟、疟母。大法风暑当发汗，夏月多在风凉处歇，遂闭其汗而不泄故也。恶饮食者，必自饮食上得之。无汗者要有汗，散邪为主，带补；有汗者要无汗，正气为主，带散。一日一发者受病一月，间日一发者受病半年，三日一发者受病一年。一日连发，住一日者，气血俱病。疟病感虚者，须以人参、白术一二帖，托住其气，不使下陷，后使他药。内伤挟外邪同发，内必主痰，外以汗解散，二陈汤加柴胡、黄芩、常山、草果煎服。久疟不得汗者，二陈汤加槟榔，倍苍术、白术。一方加柴胡、葛根、川芎，一补一发，不可直截。老疟病，此系风暑，于阴分用血药，引出阳分则散。\n" +
                "  入方宜：\n" +
                "  川芎  抚芎  红花  当归  炒柏  白术  苍术  甘草  白芷\n" +
                "  上剉，水煎，露一宿，次早服。\n" +
                "  ○治疟一日间一日发者，补药带表药，后以截疟丹截之。若在阴分者，用药掣起阳分，方可截，即前药之属。\n" +
                "  （充）案：疟在阴分须彻起阳分者，即《格致论》中云：脏传出至腑，乱而失期也。又当因其汗之多寡，而为补养升发之术。下陷，谓阳气下陷入阴血中，无汗要有汗，多用川芎、苍术、干葛、升麻、柴胡之属。此丹溪治疟之微旨，学者所当知也。\n" +
                "  截疟常山饮\n" +
                "  穿山甲（炮）  草果  知母  槟榔  乌梅  甘草（炙）  常山\n" +
                "  上㕮咀，水酒一大碗，煎半碗，露一宿，临发日早服，得吐为顺。一云加半夏、柴胡，去穿山甲。如吐，加厚朴，又或加青皮、陈皮。\n" +
                "  又方\n" +
                "  柴胡  草果  常山  知母  贝母  槟榔\n" +
                "  上用酒水同煎，露一宿，临发前二时服。\n" +
                "  又治疟母，此药消导：\n" +
                "  青皮  桃仁  红花  神曲  麦芽  鳖甲（醋煮为君）  三棱  莪术  海粉  香附（并用醋煮）\n" +
                "  上为末，丸如梧子大。每服五七十丸，白汤下。\n" +
                "  又治疟，寒热头痛如破，渴饮冰水，外多汗出：\n" +
                "  人参  白术  黄芪  黄芩  黄连  山栀（炒）  川芎  苍术  半夏  天花粉\n" +
                "  上㕮咀，水二盅，姜三片，煎服。\n" +
                "  又治疟疾发渴：\n" +
                "  生芐  麦门冬  天花粉  牛膝  知母  葛根  炒柏  生甘草\n" +
                "  上㕮咀，水煎。\n" +
                "  截疟青蒿丸\n" +
                "  青蒿（半斤）  冬瓜叶  官桂  马鞭草\n" +
                "  上焙干为末，水丸胡椒大。每一两分四服，于当发之前一时服尽。又云：青蒿一两，冬青叶二两，马鞭草二两，桂二两。未知孰是，姑两存之，以俟知者。\n" +
                "  截疟方\n" +
                "  槟榔  陈皮  白术  常山（各三钱）  茯苓  乌梅  厚朴（各一钱半）\n" +
                "  上㕮咀，作二服，水酒各一盅，煎至一盅，当发前一日一服，临发日早一服，服后少睡片时。\n" +
                "  又疟疾后方\n" +
                "  白术  半夏（一两）  黄连（半两）  白芍（三钱）  陈皮（半两）\n" +
                "  上为末，粥丸梧子大。每服六十丸，姜汤下。\n" +
                "〔附录〕\n" +
                "  世用砒霜等毒，不可轻用。俗谓脾寒，此因名而迷其实也。苟因饮食所伤而得，亦未必全是寒，况其他乎？在其阳分者易治，阴分者难治。疟母必用毒药消之，行气消坚为主。东垣谓：寒疟属太阳，热疟属阳明，风疟属少阳，在三阴经则不分，总曰温疟。此言是，但三阴经说不明。作于子午卯酉日者，少阴疟也；寅申巳亥日者，厥阴疟也；辰戌丑未日者，太阴疟也。\n" +
                "  ○疟脉多弦，但热则弦而带数，寒则弦而带迟，亦有病久而脉极虚微而无力，似乎不弦，然而必于虚微之中见弦，但不搏手耳。细察可见也。\n" +
                "  ○疟又名痁疾者，其证不一。《素问》又有五脏疟、六腑疟，详矣。初得病势正炽，一二发间，未宜遽截，不问寒热多少，且用清脾饮，或草果饮，或二陈汤加草果半钱，或平胃加草果半钱、柴胡半钱，又或养胃汤加川芎、草果各半钱。热少者进取微汗，寒多者宜快脾汤。服后寒仍多者，养胃汤加附子、桂枝各半钱，独寒尤宜，不效则七枣汤；热多者宜驱疟饮，或参苏饮，每服加草果半钱。大热不除，宜小柴胡汤；渴甚者，则以五苓散入辰砂少许；独热无寒，亦与小柴胡汤。热虽剧不甚渴者，本方加桂四分，或以柴胡桂姜汤，候可截则截之；久疟疟母不愈者，宜四兽饮，间服山甲汤。\n" +
                "〔附方〕\n" +
                "  清脾汤\n" +
                "  青皮  厚朴  白术  草果  柴胡  茯苓  黄芩  半夏  甘草（炙。等分）\n" +
                "  上剉，水二盏，生姜三片，枣一枚，煎。忌生冷油腻。\n" +
                "  七枣汤\n" +
                "  附子（一个，炮，又以盐水浸，再炮。如此七次，去皮脐。又方川乌代附子，以水调陈壁土为糊，浸七次）\n" +
                "  上剉。分作二服，水二盅，姜七片，枣七枚，煎七分，当发日早温服。\n" +
                "  驱疟饮\n" +
                "  前胡  柴胡（各四两）  桂心  桔梗  厚朴  半夏（各三两）  黄芪  干姜（炮）  甘草（炙。各二两）\n" +
                "  上剉，水二盏，生姜三片，枣四枚，煎。\n" +
                "  山甲汤\n" +
                "  川山甲  木鳖子（等分）\n" +
                "  上为末，每服二钱，空心温酒调下。\n" +
                "  四兽饮</p>",
            light: true,
            chapterList: [
                { chapterName: '第一章', type: 1 },
                { chapterName: '第一节', type: 2 },
                { chapterName: '第二节', type: 2 },
                { chapterName: '第三节', type: 2 },
                { chapterName: '第二章XXX', type: 1 },
                { chapterName: '第一节', type: 2 },
                { chapterName: '第二节', type: 2 },
                { chapterName: '第三章XXX', type: 1 },
                { chapterName: '第一节', type: 2 },
                { chapterName: '第二节', type: 2 },
                { chapterName: '第三节', type: 2 },
                { chapterName: '第四节', type: 2 },
                { chapterName: '第四节', type: 2 },
                { chapterName: '第四节', type: 2 },
                { chapterName: '后记', type: 1 },
            ],
        }
    },
    beforeCreate() {
        // document.querySelector('body').setAttribute('style', 'margin: 0')
    }, //
//    beforeDestroy() {
//     document.querySelector('body').removeAttribute('style')
//   },
    mounted() {
    },
    methods: {
        changeFontSize: function (val) {
            console.log(val);
            document.getElementsByClassName("contentWrap")[0].style.fontSize = (val * 0.25 + 1) * 16 + "px"
        },
        changeTheme: function () {
            this.light = !this.light;
        }
    }
}
</script>

<style lang="scss" scoped>
.darkBack {
    color: #ccccd8;
    background-color: #111113;
}

.darkBackinfo {
    color: #ccccd8;
    background-color: #222226;
}

.lightBack {
    color: #222226;
    background-color: #f0f0f5;
}

.lightBackInfo {
    color: #222226;
    background-color: #fff;
}

.bookContainer {
    min-height: 100vh;
}

.bookChapter {
    width: 1000px;
    min-height: 100vh;
    margin: 0 auto;
    // background-color: #fff;
    .lightBottonBorder {
        border-bottom: 2px solid #f0f0f5;
    }

    .darkBottonBorder {
        border-bottom: 2px solid #111113;
    }

    .bookHeader {
        box-sizing: border-box;
        position: fixed;
        top: 60px;
        height: 68px;
        width: 1000px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        background-color: #fff;
        .lightA {
            background: #eee;
        }

        .darkA {
            background-color: #464444;
        }

        a {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
            }
        }

        a:hover {
            background-color: #fc5531;
        }

        .bookTitle {
            padding-left: 16px;

            h1 {
                margin: 0;
                // color: #222226;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
            }

            h2 {
                margin: 0;
                // color: #555666;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    .chapterMain {
        padding-top: 108px;
        width: 810px;
        margin: 0 auto;

        .contentWrap {
            margin-bottom: 60px;

            p {
                margin-top: 0;
                letter-spacing: 1px;
                overflow-wrap: break-word;
            }
        }

        .controlWrap {
            margin: 0 auto;
            width: 360px;

            .buttons {
                display: flex;
                justify-content: space-between;
                padding-bottom: 80px;

                button {
                    cursor: pointer;
                    // background-color: #fff;
                    width: 140px;
                    height: 44px;
                    line-height: 44px;
                    text-align: center;
                    // color: #475266;
                    font-size: 14px;
                    border: 1px solid #999aaa;
                    border-radius: 5px;
                }

                .lightButton {
                    background-color: #fff;
                    color: #475266;
                }

                .darkButton {
                    background-color: #222226;
                    color: #999aaa;
                }

                .lightButton:hover {
                    background-color: #f5f5fa;
                }

                .darkButton:hover {
                    background-color: #303030;
                }

                .disabled:hover {
                    cursor: crosshair;
                }
            }
        }
    }
}

.barWrap {
    position: fixed;
    top: 42%;
    right: 1%;
    right: 50%;
    z-index: 7;
    margin-right: -564px;
    border-radius: 8px;
    // background-color: #fff;
    .readBar {
        display: flex;
        flex-direction: column;

        .barItem {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
            }

            .lightBorder {
                background-color: #fff;
                border: solid 2px #f0f0f5;
            }

            .darkBorder {
                background-color: #222226;
                border: solid 2px #303030;
            }

            .sizeControlWrap {
                position: absolute;
                top: 40px;
                right: 44px;
                // color: #999aaa;
                // background-color: #fff;
                width: 170px;
                height: 40px;
                // border:solid 2px #f0f0f5;
                border-radius: 8px;
                // box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
                transition: width .3s ease-in-out;
                width: 170px;
                overflow: hidden;
                cursor: auto;

                .sizeControlInfo {
                    padding: 0 4px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .sciItem1 {
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .sciItem2 {
                        flex-grow: 1;
                        padding: 0 12px;
                    }

                    .sciItem1 {
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .lightMiddleBarItem {
            border-top: solid 2px #f0f0f5;
            border-bottom: solid 2px #f0f0f5;
        }

        .darkMiddleBarItem {
            border-top: solid 2px #111113;
            border-bottom: solid 2px #111113;
        }
    }
}

.bookList {
    position: relative;

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    }

    .popupInfo {
        position: fixed;
        top: 0;
        right: 50%;
        z-index: 11;
        margin-right: -500px;
        width: 440px;
        height: 100%;
        font-size: 14px;
        max-height: 100%;
        // background-color: #fff;
        transition: right .3s;

        .pwiTitle {
            margin-top: 16px;
            color: #222226;
            font-size: 14px;
            font-weight: 500;
            line-height: 48px;
            text-align: center;
        }

        .pwiList {
            height: calc(100% - 80px);
            overflow: hidden;

            ul {
                overflow: auto;
                height: 100%;
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    position: relative;
                    padding-left: 40px;
                    height: 48px;
                    line-height: 47px;
                    letter-spacing: 1px;
                    box-shadow: 0 0 0 0 #e8e8ed;
                }

                .chapterLi {
                    color: #222226;
                }

                .chapterChildLi {
                    color: #999aaa;
                }

                .lightLi:hover {
                    background-color: #f9f9fc;
                }

                .darkLi:hover {
                    background-color: #303030;
                }

                // li:hover{
                //     background-color: #f9f9fc;
                // }
                .activeLi {
                    color: #fc5531;
                }
            }
        }
    }
}

.trialProgress {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 6;
    min-width: 20px;
    width: 1000px;
    height: 36px;
    margin-left: -500px;
    // background-color: #fff;
    transition: bottom .3s;
    display: flex;
    align-items: center;

    .tpText {
        font-size: 12px;
        font-weight: 400;
        color: #6f7f9b;
        line-height: 16px;
        padding-left: 24px;
    }
}
</style>
