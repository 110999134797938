<template>
    <div class="home">
        <ReadingPage></ReadingPage>
    </div>
</template>

<script>
import ReadingPage from "./ReadingPage.vue"
// @ is an alias to /src
export default {
    components: {
        ReadingPage
    },
    data() {
        return {}
    }
}
</script>
